import { compiler } from 'markdown-to-jsx';
import React from 'react';

import * as S from './RichText.styles';

export interface RichTextProps {
  className?: string;
  richText: string;
}

const defaultProps: Partial<RichTextProps> = {};

const RichText: React.FC<RichTextProps> = ({
  className,
  richText,
  ...rest
}: RichTextProps) => {
  const RTE = compiler(richText || '', {
    wrapper: null,
    // @ts-ignore
  }).map(text =>
    typeof text === 'string' || text instanceof String ? <p>{text}</p> : text
  );

  return (
    <S.Wrapper {...rest} className={className}>
      {RTE}
    </S.Wrapper>
  );
};

RichText.defaultProps = defaultProps;

export default RichText;
