import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { mediaDesktop } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Bar = styled.div`
  position: absolute;
  width: 100%;
  height: 5px;
  background-color: ${colors.green};
  transition: all 150ms ease-in;
  top: 50%;

  &:first-child {
    transform: translateY(-200%);
  }

  &:last-child {
    transform: translateY(200%);
  }
`;

interface WrapperProps {
  isOpen: boolean;
}

export const Wrapper = styled(motion.div)<WrapperProps>`
  position: relative;
  width: 50px;
  height: 60px;
  z-index: 99;

  display: flex;
  flex-direction: column;

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${Bar} {
        &:first-child {
          transform: translateY(0) rotate(-225deg);
        }

        &:last-child {
          transform: translateY(0) rotate(45deg);
        }
      }
    `}

  ${mediaDesktop(css`
    display: none;
  `)}
`;
