import { createGlobalStyle, css } from 'styled-components';

import { setTypography } from './mixins';
import resetCSS from './reset';
import { setScalableFontSize } from './responsive';
import { colors } from './theme';

const style = css`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
    user-select: text;
  }

  ::selection {
    background: ${colors.black};
    color: ${colors.white};
    text-shadow: none;
  }

  ::-moz-selection {
    background: ${colors.black};
    color: ${colors.white};
    text-shadow: none;
  }

  ::-webkit-scrollbar:horizontal {
    height: 100px;
  }

  html,
  body {
    width: 100%;
    height: 100%;
  }

  html {
    ${setScalableFontSize()}
  }

  body {
    background-color: ${colors.mediumGreen};
    color: ${colors.black};
    ${({ theme: { locale } }) => setTypography('body', locale)}
  }

  button,
  input,
  textarea,
  select {
    -webkit-appearance: none;
    background: none;
    font-family: inherit;
    border: none;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-transform: inherit;
    outline: none;
    border-radius: 0;
  }

  svg {
    outline: none;
    display: block;
  }

  button,
  div,
  a,
  span {
    -webkit-tap-highlight-color: transparent;
  }

  a {
    color: inherit;
  }

  button {
    cursor: pointer;
  }

  #gradient-canvas {
    width: 100%;
    height: 100%;
    --gradient-color-1: ${colors.mediumGreen};
    --gradient-color-2: ${colors.mediumGreen};
    --gradient-color-3: ${colors.mediumGreen};
    --gradient-color-4: ${colors.backgroundGreen};
    --gradient-color-5: ${colors.backgroundGreen};
  }
`;

export default createGlobalStyle`
  ${resetCSS}
  ${style}
`;
