import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import CtaCmp from 'components/Cta';
import InteractiveCmp from 'components/Interactive/Interactive';
import { mainGradient, setTypography } from 'utils/styles/mixins';
import { mediaDesktop } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${setTypography('heading2')}
  color: ${colors.white};

  ${mainGradient}

  ${mediaDesktop(css`
    display: none;
  `)}
`;

export const MenuItem = styled.span``;

export const Cta = styled(CtaCmp)``;

export const Interactive = styled(InteractiveCmp)`
  width: auto;
  cursor: pointer;

  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }
`;
