import { useEffect, useState } from 'react';

import { useWindowStore } from 'store';
import * as VARS from 'utils/styles/vars';

export default function useScreenBreakpoints() {
  const { setCurrentBreakpoint } = useWindowStore();
  const [setup, setSetup] = useState(false);

  useEffect(() => {
    window.addEventListener('resize', checkCurBreakpoint);
    checkCurBreakpoint();
    setSetup(true);

    return () => {
      window.removeEventListener('resize', checkCurBreakpoint);
    };
  }, []);

  const checkCurBreakpoint = () => {
    const isMobile = window.innerWidth < VARS.breakpointTablet;
    const isTablet = !isMobile && window.innerWidth < VARS.breakpointDesktop;
    const isDesktop =
      !isMobile && !isTablet && window.innerWidth >= VARS.breakpointDesktop;

    setCurrentBreakpoint({
      isMobile,
      isTablet,
      isDesktop,
    });
  };

  return setup;
}
