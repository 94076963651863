import create from 'zustand';

export interface GlobalStoreType {
  activeSection: string;
  setActiveSection: (state: string) => void;
}

const useArticleStore = create(
  (
    set: (partial: (store: GlobalStoreType) => Partial<GlobalStoreType>) => void
  ) => {
    return {
      activeSection: null,
      setActiveSection: state => {
        set(() => ({
          activeSection: state,
        }));
      },
    };
  }
);

export default useArticleStore;
