import { GetStaticProps } from 'next';
import React from 'react';

import Background from 'components/Background/Background';
import CookieBar from 'components/CookieBar/CookieBar';
import Footer from 'components/Footer';
import Header from 'components/Header';
import MobileMenu from 'components/MobileMenu';
import { CustomPageProps } from 'pages/_app';
import { ISR_TIMEOUT } from 'utils/config';

import * as S from './Layout.styles';

export interface LayoutProps extends CustomPageProps {
  className?: string;
  children?: any;
}

const defaultProps: Partial<LayoutProps> = {};

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {},
    revalidate: ISR_TIMEOUT,
  };
};

const Layout: React.FC<LayoutProps> = ({
  className,
  children,
  ...restProps
}: LayoutProps) => {
  return (
    <S.Wrapper className={className}>
      <Background />
      <Header data={restProps.pageData?.header} cta={restProps.pageData?.cta} />
      <S.Content>{children}</S.Content>
      <MobileMenu data={restProps.pageData?.header} />
      <Footer data={restProps.footer} />
      <CookieBar data={restProps.cookies} />
    </S.Wrapper>
  );
};

Layout.defaultProps = defaultProps;

export default Layout;
