import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Wrapper = styled(motion.canvas)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: 100dvh;
`;
