export enum ColorNames {
  white = 'white',
  black = 'black',
  green = 'green',
  mediumGreen = 'mediumGreen',
  darkGreen = 'darkGreen',
  backgroundGreen = 'backgroundGreen',
  gray = 'gray',
}

export enum FontNames {
  rajdhani = 'rajdhani',
}

export const validFontCategories = [
  'display',
  'heading1',
  'heading2',
  'heading3',
  'articleH3',
  'heading4',
  'button',
  'label',
  'subtitle',
  'body',
  'smallBody',
] as const;

interface ResponsiveValues {
  mobile?: number;
  desktop?: number;
}

interface FontCategory {
  fontFamily?: FontNames | Record<string, FontNames>;
  fontSize: ResponsiveValues;
  lineHeight: ResponsiveValues;
  fontWeight: number;
  letterSpacing?: number;
  wordSpacing?: number;
  fontStretch?: 'expanded' | 'normal';
  textDecoration?: 'underline' | 'none';
  textTransform?:
    | 'none'
    | 'capitalize'
    | 'uppercase'
    | 'full-size-kana'
    | 'full-width'
    | 'lowercase';
  fontStyle?: 'normal' | 'italic' | 'oblique' | 'inherit' | 'initial' | 'unset';
}

interface Theme {
  colors: Record<ColorNames, string>;
  fonts: {
    face: Record<FontNames, string>;
    scale: Record<typeof validFontCategories[number], FontCategory>;
  };
  layout: typeof layout;
}

// "Name that Color" names
export const colors: Theme['colors'] = {
  [ColorNames.white]: '#fff',
  [ColorNames.black]: '#000',
  [ColorNames.gray]: '#f3f4f4',

  [ColorNames.green]: '#00E600',
  [ColorNames.mediumGreen]: '#2A5B44',
  [ColorNames.darkGreen]: '#002300',
  [ColorNames.backgroundGreen]: '#0b7b42',
} as const;

export const DARK_BG = 0;
export const LIGHT_BG = 1;

export const darkBackgroundColors = [colors.black, colors.darkGreen];

export const lightBackgroundColors = [
  colors.mediumGreen,
  colors.backgroundGreen,
];

export const fonts: Theme['fonts'] = {
  face: {
    [FontNames.rajdhani]:
      // eslint-disable-next-line quotes
      "'Rajdhani', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  },
  scale: {
    // Based on Material design typography scale, adjust as needed
    // fontSize: rem, letterSpacing: em
    // fontFamily: { [localeCode]: localeSpecificFont, default: defaultFont }
    display: {
      fontFamily: { default: FontNames.rajdhani },
      fontSize: { desktop: 7, mobile: 5.8 },
      lineHeight: { desktop: 1.1, mobile: 1.1 },
      fontWeight: 700,
    },
    heading1: {
      fontFamily: { default: FontNames.rajdhani },
      fontSize: { desktop: 6, mobile: 4.4 },
      lineHeight: { desktop: 1.2, mobile: 1.2 },
      fontWeight: 600,
    },
    heading2: {
      fontFamily: { default: FontNames.rajdhani },
      fontSize: { desktop: 4.8, mobile: 3.7 },
      lineHeight: { desktop: 1.2, mobile: 1.2 },
      fontWeight: 600,
    },
    heading3: {
      fontFamily: { default: FontNames.rajdhani },
      fontSize: { desktop: 2.4, mobile: 2.8 },
      lineHeight: { desktop: 1.2, mobile: 1.2 },
      fontWeight: 600,
    },
    articleH3: {
      fontFamily: { default: FontNames.rajdhani },
      fontSize: { desktop: 3.1, mobile: 3 },
      lineHeight: { desktop: 1.2, mobile: 1.2 },
      fontWeight: 600,
    },
    heading4: {
      fontFamily: { default: FontNames.rajdhani },
      fontSize: { desktop: 2.2, mobile: 2.4 },
      lineHeight: { desktop: 1.2, mobile: 1.2 },
      fontWeight: 600,
    },
    button: {
      fontFamily: { default: FontNames.rajdhani },
      fontSize: { desktop: 2.4, mobile: 2.4 },
      lineHeight: { desktop: 1.5, mobile: 1.5 },
      fontWeight: 600,
      letterSpacing: 0.03,
    },
    label: {
      fontFamily: { default: FontNames.rajdhani },
      fontSize: { desktop: 2, mobile: 2 },
      lineHeight: { desktop: 1.5, mobile: 1.5 },
      fontWeight: 600,
    },
    subtitle: {
      fontFamily: { default: FontNames.rajdhani },
      fontSize: { desktop: 3.2, mobile: 2.4 },
      lineHeight: { desktop: 1.2, mobile: 1.2 },
      fontWeight: 500,
      letterSpacing: 0.03,
    },
    body: {
      fontFamily: { default: FontNames.rajdhani },
      fontSize: { desktop: 2, mobile: 2 },
      lineHeight: { desktop: 1.7, mobile: 1.6 },
      fontWeight: 500,
    },
    smallBody: {
      fontFamily: { default: FontNames.rajdhani },
      fontSize: { desktop: 1.6, mobile: 1.6 },
      lineHeight: { desktop: 1.7, mobile: 1.7 },
      fontWeight: 500,
      letterSpacing: 0.05,
    },
  },
} as const;

// Comment unit where applicable
export const layout = {
  borderRadius: 15, // rem
  lineOpacity: 0.1,
  zIndex: {
    languageSelector: 2,
    nonFunctionals: 3,
  },
} as const;

const theme: Theme = {
  colors,
  fonts,
  layout,
} as const;

export default theme;
