import styled from 'styled-components';

import { grid } from 'utils/styles/mixins';

export const Wrapper = styled.div`
  display: grid;
  ${grid()}
  position: relative;
`;

export const Content = styled.div`
  grid-column: 1/-1;
  z-index: 2;
`;
