import React from 'react';

import * as S from './BurgerMenuButton.styles';

export interface BurgerMenuButtonProps {
  className?: string;
  isOpen: boolean;
  onClick: () => void;
}

const defaultProps: Partial<BurgerMenuButtonProps> = {};

const BurgerMenuButton: React.FC<BurgerMenuButtonProps> = ({
  isOpen,
  onClick,
  className,
}: BurgerMenuButtonProps) => {
  return (
    <S.Wrapper className={className} isOpen={isOpen} onClick={onClick}>
      <S.Bar />
      <S.Bar />
    </S.Wrapper>
  );
};

BurgerMenuButton.defaultProps = defaultProps;

export default BurgerMenuButton;
