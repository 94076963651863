import { HTMLMotionProps } from 'framer-motion';
import Link from 'next/link';
import React from 'react';

import Interactive from 'components/Interactive/Interactive';

import * as S from './Cta.styles';

export interface CtaProps {
  className?: string;
  label: string;
  href?: string;
  onClick?: () => void;
  empty?: boolean;
}

const defaultProps: Partial<CtaProps> = {
  empty: false,
};

const Cta: React.FC<CtaProps & HTMLMotionProps<'div'>> = ({
  className,
  label,
  href,
  onClick,
  empty,
  ...rest
}: CtaProps) => {
  return (
    <S.Wrapper {...rest} className={className}>
      <Interactive>
        {onClick ? (
          <S.Button onClick={onClick} empty={empty}>
            {label}
          </S.Button>
        ) : (
          <Link href={href}>
            <S.Button empty={empty}>{label}</S.Button>
          </Link>
        )}
      </Interactive>
    </S.Wrapper>
  );
};

Cta.defaultProps = defaultProps;

export default Cta;
