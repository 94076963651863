import React from 'react';

import { colors } from 'utils/styles/theme';

import * as S from './CheckBox.styles';

export interface CheckBoxProps {
  className?: string;
  checked: boolean;
  onClick: () => void;
}

const defaultProps: Partial<CheckBoxProps> = {};

const CheckBox: React.FC<CheckBoxProps> = ({
  className,
  checked,
  onClick,
}: CheckBoxProps) => {
  return (
    <S.Wrapper onClick={onClick} className={className}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 19 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.0373 7.09544C17.8373 7.09544 17.6455 7.01589 17.504 6.8743C17.3625 6.73272 17.283 6.54068 17.283 6.34044V2.74518C17.283 2.01714 16.994 1.31893 16.4797 0.804132C15.9655 0.289333 15.2679 0.00012207 14.5405 0.00012207H10.2237C10.1793 0.00012207 10.1367 0.0177825 10.1053 0.0492188C10.0739 0.0806549 10.0562 0.123292 10.0562 0.167749V1.17351C10.0562 1.21797 10.0739 1.2606 10.1053 1.29204C10.1367 1.32348 10.1793 1.34114 10.2237 1.34114H14.5405C14.9126 1.34114 15.2693 1.48906 15.5323 1.75237C15.7955 2.01568 15.9433 2.37281 15.9433 2.74518V6.34447C15.9437 6.87383 16.1448 7.38331 16.506 7.76997C16.1442 8.15633 15.9431 8.66601 15.9433 9.19547V12.7934C15.9438 12.9782 15.9079 13.1613 15.8377 13.3321C15.7675 13.5031 15.6642 13.6584 15.5339 13.7892C15.4036 13.9203 15.2488 14.0241 15.0783 14.0951C14.9079 14.1661 14.7251 14.2027 14.5405 14.2028H10.2237C10.1793 14.2028 10.1367 14.2205 10.1053 14.2519C10.0739 14.2834 10.0562 14.3259 10.0562 14.3704V15.3762C10.0562 15.4207 10.0739 15.4632 10.1053 15.4948C10.1367 15.5261 10.1793 15.5438 10.2237 15.5438H14.5405C15.268 15.5434 15.9656 15.2539 16.4798 14.739C16.9942 14.224 17.283 13.5256 17.283 12.7974V9.19547C17.283 8.99522 17.3625 8.80319 17.504 8.66161C17.6455 8.52002 17.8373 8.44047 18.0373 8.44047H18.3616C18.4061 8.44047 18.4485 8.42281 18.48 8.39138C18.5114 8.35995 18.529 8.3173 18.529 8.27285V7.26709C18.529 7.22263 18.5114 7.17999 18.48 7.14855C18.4485 7.11712 18.4061 7.09946 18.3616 7.09946L18.0373 7.09544Z"
          fill={colors.green}
        />
        <path
          d="M2.58536 2.74518C2.58536 2.37304 2.73286 2.01611 2.99547 1.75284C3.25806 1.48957 3.61427 1.34149 3.98582 1.34114H8.2355C8.27988 1.34114 8.32246 1.32348 8.35384 1.29204C8.38523 1.2606 8.4728 1.21797 8.4728 1.17351V0.167749C8.4728 0.123292 8.45517 0.0806549 8.42379 0.0492188C8.3924 0.0177825 8.34983 0.00012207 8.30545 0.00012207L3.98582 0.00012207C3.62597 0.000122017 3.26966 0.0711347 2.93722 0.209103C2.60477 0.347072 2.30274 0.549293 2.04835 0.804212C1.79395 1.05913 1.59221 1.36176 1.45462 1.69479C1.31703 2.02783 1.24631 2.38476 1.24649 2.74518V6.34447C1.24543 6.54401 1.16554 6.73501 1.0243 6.87572C0.883048 7.01645 0.691927 7.09544 0.492705 7.09544H0.167359C0.122973 7.09544 0.080404 7.1131 0.0490181 7.14453C0.0176323 7.17596 0 7.21861 0 7.26306V8.26882C0 8.31328 0.0176323 8.35592 0.0490181 8.38736C0.080404 8.41879 0.122973 8.43645 0.167359 8.43645H0.492705C0.69262 8.43645 0.884349 8.516 1.02571 8.65758C1.16707 8.79917 1.24649 8.9912 1.24649 9.19144V12.7894C1.24526 13.1505 1.31519 13.5084 1.45229 13.8425C1.58939 14.1765 1.79094 14.4801 2.04542 14.736C2.2999 14.9919 2.60228 15.195 2.93525 15.3336C3.26823 15.4722 3.62524 15.5437 3.98582 15.5438H8.30545C8.34983 15.5438 8.3924 15.5261 8.42379 15.4948C8.45517 15.4632 8.4728 15.4207 8.4728 15.3762V14.3704C8.4728 14.3259 8.45517 14.2834 8.42379 14.2519C8.3924 14.2205 8.34983 14.2028 8.30545 14.2028H3.98582C3.80162 14.2028 3.61922 14.1665 3.44907 14.0958C3.27891 14.0251 3.12431 13.9216 2.99413 13.7911C2.86394 13.6606 2.76071 13.5056 2.69034 13.3352C2.61997 13.1646 2.58385 12.9819 2.58402 12.7974V9.19547C2.58507 8.66464 2.38397 8.15339 2.0217 7.76594C2.38304 7.37942 2.58449 6.86996 2.58536 6.34044V2.74518Z"
          fill={colors.green}
        />
      </svg>
      {checked && <S.Checked />}
    </S.Wrapper>
  );
};

CheckBox.defaultProps = defaultProps;

export default CheckBox;
