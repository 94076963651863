import React from 'react';

import { colors } from 'utils/styles/theme';

import * as S from './Logo.styles';

export interface LogoProps {
  className?: string;
  nameColor?: string;
  logoColor?: string;
  animate?: any;
}

const defaultProps: Partial<LogoProps> = {};

const Logo: React.FC<LogoProps> = ({
  className,
  nameColor = colors.white,
  logoColor = colors.green,
  ...rest
}: LogoProps) => {
  return (
    <S.SVG
      className={className}
      // @ts-ignore
      width="100%"
      height="100%"
      viewBox="0 0 220 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_327_1125)">
        <path
          d="M119.455 25.5067H115.256C114.87 25.5067 114.557 25.8251 114.557 26.2179V48.2312C114.557 48.624 114.87 48.9424 115.256 48.9424H119.455C119.841 48.9424 120.155 48.624 120.155 48.2312V26.2179C120.155 25.8251 119.841 25.5067 119.455 25.5067Z"
          fill={nameColor}
        />
        <path
          d="M119.455 17.0575H115.256C114.87 17.0575 114.557 17.3759 114.557 17.7687V21.9563C114.557 22.3491 114.87 22.6675 115.256 22.6675H119.455C119.841 22.6675 120.155 22.3491 120.155 21.9563V17.7687C120.155 17.3759 119.841 17.0575 119.455 17.0575Z"
          fill={nameColor}
        />
        <path
          d="M150.96 25.5067H146.762C146.376 25.5067 146.062 25.8251 146.062 26.2179V48.2312C146.062 48.624 146.376 48.9424 146.762 48.9424H150.96C151.347 48.9424 151.66 48.624 151.66 48.2312V26.2179C151.66 25.8251 151.347 25.5067 150.96 25.5067Z"
          fill={nameColor}
        />
        <path
          d="M150.96 17.0575H146.762C146.376 17.0575 146.062 17.3759 146.062 17.7687V21.9563C146.062 22.3491 146.376 22.6675 146.762 22.6675H150.96C151.347 22.6675 151.66 22.3491 151.66 21.9563V17.7687C151.66 17.3759 151.347 17.0575 150.96 17.0575Z"
          fill={nameColor}
        />
        <path
          d="M173.179 17.069H168.98C168.794 17.069 168.617 17.1439 168.485 17.2773C168.354 17.4106 168.28 17.5916 168.28 17.7802V27.2535H168.084C167.562 26.7117 166.939 26.2817 166.25 25.9886C165.561 25.6955 164.823 25.5451 164.076 25.5466H161.356C159.871 25.5466 158.447 26.146 157.397 27.213C156.348 28.28 155.758 29.7272 155.758 31.2362V43.2983C155.758 44.8073 156.348 46.2545 157.397 47.3215C158.447 48.3885 159.871 48.9879 161.356 48.9879H164.076C164.824 48.9901 165.564 48.8402 166.253 48.547C166.942 48.2539 167.567 47.8234 168.09 47.281H168.28V48.254C168.28 48.4426 168.354 48.6235 168.485 48.7569C168.617 48.8902 168.794 48.9652 168.98 48.9652H173.179C173.364 48.9652 173.542 48.8902 173.673 48.7569C173.804 48.6235 173.878 48.4426 173.878 48.254V17.7688C173.876 17.5822 173.801 17.4041 173.67 17.2732C173.539 17.1423 173.362 17.069 173.179 17.069ZM165.481 43.3097H162.755C162.384 43.3097 162.028 43.1598 161.765 42.8931C161.503 42.6263 161.356 42.2645 161.356 41.8872V32.6302C161.356 32.253 161.503 31.8911 161.765 31.6244C162.028 31.3576 162.384 31.2078 162.755 31.2078H165.481C166.224 31.2078 166.936 31.5075 167.461 32.041C167.985 32.5745 168.28 33.2981 168.28 34.0526V40.4591C168.279 41.2126 167.983 41.9348 167.459 42.4671C166.934 42.9993 166.223 43.2983 165.481 43.2983V43.3097Z"
          fill={nameColor}
        />
        <path
          d="M142.524 25.5067H138.41C138.261 25.5068 138.117 25.5548 137.997 25.6438C137.877 25.7328 137.789 25.8581 137.744 26.0017L133.136 43.139H133.013L128.406 26.0017C128.361 25.8581 128.272 25.7328 128.153 25.6438C128.033 25.5548 127.888 25.5068 127.74 25.5067H123.625C123.515 25.5077 123.407 25.535 123.309 25.5864C123.211 25.6377 123.126 25.7118 123.061 25.8026C122.996 25.8933 122.953 25.9982 122.936 26.1089C122.918 26.2196 122.926 26.3329 122.959 26.4398L129.151 48.51C129.198 48.6521 129.287 48.7757 129.406 48.8634C129.526 48.9512 129.67 48.9987 129.817 48.9993H136.333C136.48 48.9987 136.624 48.9512 136.743 48.8634C136.863 48.7757 136.952 48.6521 136.999 48.51L143.19 26.4398C143.223 26.3329 143.232 26.2196 143.214 26.1089C143.196 25.9982 143.153 25.8933 143.088 25.8026C143.024 25.7118 142.939 25.6377 142.841 25.5864C142.743 25.535 142.634 25.5077 142.524 25.5067Z"
          fill={nameColor}
        />
        <path
          d="M219.183 25.5067H215.068C214.92 25.5068 214.775 25.5548 214.656 25.6438C214.536 25.7328 214.447 25.8581 214.402 26.0017L209.795 43.139H209.672L205.065 26.0017C205.019 25.8581 204.931 25.7328 204.811 25.6438C204.691 25.5548 204.547 25.5068 204.398 25.5067H200.284C200.174 25.5077 200.065 25.535 199.967 25.5864C199.869 25.6377 199.784 25.7118 199.72 25.8026C199.655 25.8933 199.612 25.9982 199.594 26.1089C199.576 26.2196 199.585 26.3329 199.618 26.4398L205.809 48.51C205.856 48.6521 205.945 48.7757 206.065 48.8634C206.184 48.9512 206.328 48.9987 206.475 48.9993H212.991C213.139 48.9987 213.282 48.9512 213.402 48.8634C213.521 48.7757 213.61 48.6521 213.658 48.51L219.849 26.4398C219.882 26.3329 219.89 26.2196 219.872 26.1089C219.855 25.9982 219.812 25.8933 219.747 25.8026C219.683 25.7118 219.598 25.6377 219.5 25.5864C219.402 25.535 219.293 25.5077 219.183 25.5067Z"
          fill={nameColor}
        />
        <path
          d="M110.89 47.8557L104.962 37.1762C104.899 37.0676 104.866 36.9438 104.866 36.8177C104.866 36.6916 104.899 36.5679 104.962 36.4593L110.252 26.5763C110.314 26.4682 110.347 26.3455 110.347 26.2203C110.348 26.0952 110.316 25.9722 110.254 25.8636C110.193 25.7551 110.105 25.665 109.998 25.6023C109.891 25.5396 109.77 25.5066 109.647 25.5067H104.794C104.67 25.5068 104.549 25.54 104.442 25.6029C104.334 25.6658 104.245 25.7562 104.184 25.8651L98.6809 36.1065H98.569V17.746C98.569 17.5574 98.4951 17.3765 98.3641 17.2431C98.2325 17.1097 98.0551 17.0348 97.8692 17.0348H93.6707C93.4849 17.0348 93.3074 17.1097 93.1759 17.2431C93.0449 17.3765 92.971 17.5574 92.971 17.746V48.2312C92.971 48.4198 93.0449 48.6007 93.1759 48.7341C93.3074 48.8674 93.4849 48.9424 93.6707 48.9424H97.8692C98.0551 48.9424 98.2325 48.8674 98.3641 48.7341C98.4951 48.6007 98.569 48.4198 98.569 48.2312V37.5915H98.6753L104.799 48.5839C104.862 48.6899 104.951 48.7775 105.057 48.8383C105.163 48.8991 105.283 48.9311 105.404 48.931H110.28C110.404 48.9326 110.527 48.9006 110.635 48.838C110.743 48.7754 110.833 48.6848 110.895 48.5752C110.957 48.4656 110.99 48.3411 110.989 48.2147C110.988 48.0882 110.954 47.9643 110.89 47.8557Z"
          fill={nameColor}
        />
        <path
          d="M183.888 40.1746H195.369C195.74 40.1746 196.096 40.0248 196.359 39.758C196.621 39.4912 196.768 39.1294 196.768 38.7522V31.2077C196.768 29.6987 196.178 28.2515 195.129 27.1845C194.079 26.1175 192.655 25.5181 191.17 25.5181H183.613C182.129 25.5181 180.705 26.1175 179.655 27.1845C178.605 28.2515 178.015 29.6987 178.015 31.2077V43.2413C178.015 44.7504 178.605 46.1975 179.655 47.2646C180.705 48.3315 182.129 48.931 183.613 48.931H195.873C196.059 48.931 196.236 48.8561 196.368 48.7227C196.499 48.5893 196.573 48.4084 196.573 48.2198V43.9525C196.573 43.7639 196.499 43.583 196.368 43.4496C196.236 43.3163 196.059 43.2413 195.873 43.2413H185.013C184.642 43.2413 184.286 43.0915 184.023 42.8247C183.76 42.558 183.613 42.1962 183.613 41.8189V40.4705L183.888 40.1746ZM185.007 31.2077H189.771C190.142 31.2077 190.498 31.3576 190.761 31.6243C191.023 31.8911 191.17 32.2529 191.17 32.6301V35.1905C191.17 35.3791 191.097 35.56 190.966 35.6934C190.834 35.8268 190.657 35.9017 190.471 35.9017H183.888L183.596 35.6115V32.6301C183.596 32.2529 183.744 31.8911 184.006 31.6243C184.269 31.3576 184.625 31.2077 184.996 31.2077H185.007Z"
          fill={nameColor}
        />
        <path
          d="M10.4389 11.6979C10.4389 10.1189 11.0556 8.60458 12.1536 7.48758C13.2516 6.37059 14.7409 5.74228 16.2944 5.7408H34.0627C34.2483 5.7408 34.4263 5.66588 34.5576 5.53249C34.6888 5.39912 35.0549 5.21822 35.0549 5.0296V0.762354C35.0549 0.573731 34.9812 0.392832 34.85 0.259455C34.7188 0.126077 34.5408 0.0511475 34.3552 0.0511475L16.2944 0.0511475C14.7898 0.0511472 13.3 0.35244 11.9101 0.937812C10.5201 1.52319 9.25724 2.38117 8.19363 3.46274C7.12996 4.54431 6.28645 5.82831 5.71115 7.24128C5.13591 8.6543 4.84021 10.1687 4.84095 11.6979V26.9689C4.83651 27.8155 4.5025 28.6259 3.91193 29.2229C3.32136 29.82 2.52226 30.1551 1.68929 30.1551H0.32899C0.143406 30.1551 -0.0345802 30.23 -0.165808 30.3634C-0.297036 30.4968 -0.370758 30.6777 -0.370758 30.8663V35.1336C-0.370758 35.3222 -0.297036 35.5031 -0.165808 35.6365C-0.0345802 35.7698 0.143406 35.8448 0.32899 35.8448H1.68929C2.52516 35.8448 3.3268 36.1823 3.91785 36.783C4.5089 37.3837 4.84095 38.1985 4.84095 39.048V54.3134C4.83579 55.8457 5.12818 57.3642 5.70141 58.7815C6.27464 60.1988 7.11736 61.4869 8.18137 62.5725C9.24537 63.6581 10.5097 64.5201 11.9018 65.1078C13.294 65.6961 14.7867 65.9994 16.2944 65.9999H34.3552C34.5408 65.9999 34.7188 65.9248 34.85 65.7917C34.9812 65.658 35.0549 65.4776 35.0549 65.2887V61.0215C35.0549 60.8326 34.9812 60.6522 34.85 60.5185C34.7188 60.3854 34.5408 60.3103 34.3552 60.3103H16.2944C15.5242 60.3103 14.7616 60.1561 14.0502 59.8563C13.3387 59.5564 12.6923 59.1172 12.148 58.5636C11.6037 58.01 11.1721 57.3522 10.8778 56.629C10.5836 55.9055 10.4326 55.1303 10.4333 54.3475V39.0651C10.4377 36.8129 9.59687 34.6438 8.08217 32.9999C9.593 31.36 10.4353 29.1985 10.4389 26.9518V11.6979Z"
          fill={logoColor}
        />
        <path
          d="M73.2371 30.1551C72.4013 30.1551 71.5997 29.8176 71.0085 29.2169C70.4174 28.6162 70.0854 27.8014 70.0854 26.9518V11.6979C70.0854 8.60896 68.8779 5.64658 66.7289 3.4624C64.5804 1.27821 61.6655 0.0511475 58.6264 0.0511475H40.5899C40.4043 0.0511475 40.2263 0.126077 40.095 0.259455C39.9638 0.392832 39.8901 0.573731 39.8901 0.762354V5.0296C39.8901 5.21822 39.9638 5.39912 40.095 5.53249C40.2263 5.66588 40.4043 5.7408 40.5899 5.7408H58.6264C60.1809 5.7408 61.6717 6.36843 62.7705 7.48559C63.87 8.60276 64.4874 10.118 64.4874 11.6979V26.9689C64.4891 29.2149 65.3294 31.3765 66.8386 33.017C65.3271 34.6563 64.4869 36.8187 64.4874 39.0651V54.3305C64.4897 55.1144 64.3397 55.8912 64.0463 56.6162C63.753 57.3414 63.3214 58.0003 62.7773 58.5556C62.2326 59.1115 61.586 59.5519 60.8734 59.8534C60.1613 60.1544 59.3978 60.3097 58.6264 60.3103H40.5899C40.4043 60.3103 40.2263 60.3854 40.095 60.5185C39.9638 60.6522 39.8901 60.8326 39.8901 61.0215V65.2887C39.8901 65.4776 39.9638 65.658 40.095 65.7917C40.2263 65.9248 40.4043 65.9999 40.5899 65.9999H58.6264C61.6661 65.9982 64.5809 64.7698 66.7294 62.585C68.8785 60.4002 70.0854 57.437 70.0854 54.3475V39.0651C70.0854 38.2155 70.4174 37.4008 71.0085 36.8001C71.5997 36.1993 72.4013 35.8618 73.2371 35.8618H74.5918C74.7776 35.8618 74.9551 35.7869 75.0866 35.6535C75.2176 35.5202 75.2915 35.3392 75.2915 35.1506V30.8834C75.2915 30.6948 75.2176 30.5138 75.0866 30.3805C74.9551 30.2471 74.7776 30.1722 74.5918 30.1722L73.2371 30.1551Z"
          fill={logoColor}
        />
        <path
          d="M39.8911 11.0209C39.8911 11.8572 38.945 13.769 38.2565 15.089C38.1898 15.2189 38.0892 15.3279 37.9658 15.4038C37.8425 15.4798 37.701 15.52 37.5567 15.52C37.4125 15.52 37.271 15.4798 37.1477 15.4038C37.0243 15.3279 36.9237 15.2189 36.857 15.089C36.1685 13.769 35.228 11.8572 35.228 11.0209C35.2573 10.4125 35.5158 9.83884 35.9498 9.41877C36.3839 8.9987 36.9602 8.7644 37.5595 8.7644C38.1589 8.7644 38.7352 8.9987 39.1692 9.41877C39.6033 9.83884 39.8618 10.4125 39.8911 11.0209Z"
          fill={logoColor}
        />
        <path
          d="M16.1501 30.6501C16.973 30.6501 19.0835 31.6117 20.3822 32.3115C20.51 32.3793 20.6172 32.4815 20.692 32.6069C20.7668 32.7323 20.8063 32.8761 20.8063 33.0227C20.8063 33.1694 20.7668 33.3132 20.692 33.4385C20.6172 33.5639 20.51 33.6661 20.3822 33.7339C19.0835 34.4338 16.973 35.3896 16.1501 35.3896C15.5515 35.3598 14.9871 35.0971 14.5738 34.656C14.1605 34.2148 13.93 33.629 13.93 33.0199C13.93 32.4108 14.1605 31.8249 14.5738 31.3838C14.9871 30.9427 15.5515 30.68 16.1501 30.6501Z"
          fill={logoColor}
        />
        <path
          d="M58.9745 35.3895C58.1516 35.3895 56.0412 34.4337 54.7425 33.7338C54.6146 33.666 54.5074 33.5638 54.4327 33.4384C54.3579 33.3131 54.3184 33.1693 54.3184 33.0226C54.3184 32.876 54.3579 32.7322 54.4327 32.6068C54.5074 32.4814 54.6146 32.3793 54.7425 32.3114C56.0412 31.6116 58.1516 30.6501 58.9745 30.6501C59.2903 30.6344 59.606 30.684 59.9021 30.7959C60.1988 30.908 60.4698 31.08 60.6987 31.3016C60.9277 31.5231 61.1096 31.7897 61.2344 32.0851C61.3587 32.3804 61.4231 32.6984 61.4231 33.0198C61.4231 33.3411 61.3587 33.6591 61.2344 33.9545C61.1096 34.2499 60.9277 34.5165 60.6987 34.738C60.4698 34.9596 60.1988 35.1317 59.9021 35.2437C59.606 35.3556 59.2903 35.4052 58.9745 35.3895Z"
          fill={logoColor}
        />
        <path
          d="M35.228 55.0075C35.228 54.1711 36.1741 52.2594 36.857 50.9394C36.9251 50.811 37.0261 50.7038 37.1493 50.629C37.2724 50.5543 37.4133 50.5148 37.5567 50.5148C37.7002 50.5148 37.841 50.5543 37.9642 50.629C38.0874 50.7038 38.1884 50.811 38.2565 50.9394C38.945 52.2594 39.8911 54.1711 39.8911 55.0075C39.8618 55.6159 39.6033 56.1895 39.1692 56.6096C38.7352 57.0296 38.1589 57.264 37.5595 57.264C36.9602 57.264 36.3839 57.0296 35.9498 56.6096C35.5158 56.1895 35.2573 55.6159 35.228 55.0075Z"
          fill={logoColor}
        />
        <path
          d="M32.9328 18.7758L23.9761 27.8792C23.7731 28.084 23.6584 28.3623 23.657 28.653V37.4378C23.6584 37.7285 23.7731 38.0068 23.9761 38.2116L32.9328 47.3151C33.0831 47.4684 33.2749 47.5729 33.4837 47.6152C33.6926 47.6576 33.9091 47.636 34.1058 47.5531C34.3025 47.4702 34.4706 47.3297 34.5886 47.1496C34.7067 46.9695 34.7694 46.7577 34.7689 46.5413V41.6482C34.7693 41.5046 34.7418 41.3623 34.688 41.2295C34.6342 41.0968 34.5552 40.9761 34.4555 40.8744L26.7247 32.9999L34.4555 25.1368C34.5552 25.0351 34.6342 24.9144 34.688 24.7816C34.7418 24.6488 34.7693 24.5066 34.7689 24.363V19.5325C34.7661 19.3175 34.701 19.1081 34.5817 18.9306C34.4624 18.753 34.2941 18.6152 34.0981 18.5344C33.902 18.4536 33.6869 18.4334 33.4796 18.4764C33.2723 18.5193 33.0821 18.6234 32.9328 18.7758Z"
          fill={logoColor}
        />
        <path
          d="M42.1919 47.2695L51.1486 38.1661C51.2483 38.0644 51.3273 37.9437 51.3811 37.8109C51.4349 37.6781 51.4624 37.5359 51.4621 37.3923V28.6245C51.4624 28.4809 51.4349 28.3387 51.3811 28.2059C51.3273 28.0732 51.2483 27.9525 51.1486 27.8507L42.1919 18.7473C42.0408 18.5948 41.8488 18.4911 41.6398 18.4492C41.431 18.4072 41.2146 18.429 41.0178 18.5116C40.821 18.5943 40.6526 18.7342 40.5339 18.9138C40.4151 19.0934 40.3512 19.3047 40.3501 19.5211V24.3687C40.3515 24.6594 40.4663 24.9376 40.6692 25.1425L48.4 32.9999L40.6692 40.8573C40.4663 41.0621 40.3515 41.3404 40.3501 41.6311V46.4787C40.3478 46.6965 40.4094 46.9102 40.5269 47.0924C40.6444 47.2746 40.8125 47.4172 41.01 47.502C41.2075 47.5868 41.4253 47.61 41.6358 47.5685C41.8462 47.5272 42.0398 47.4231 42.1919 47.2695Z"
          fill={logoColor}
        />
        <path
          d="M24.6926 16.5852C25.2524 17.1542 25.9745 19.248 26.4056 20.6761C26.4493 20.8162 26.4542 20.9658 26.42 21.1085C26.3857 21.2513 26.3135 21.3817 26.2113 21.4857C26.109 21.5896 25.9807 21.663 25.8402 21.6978C25.6997 21.7326 25.5526 21.7275 25.4148 21.6832C24.0097 21.2394 21.972 20.5452 21.3898 19.9364C21.1633 19.7183 20.9822 19.4563 20.8571 19.1658C20.7319 18.8753 20.6654 18.5623 20.6613 18.2452C20.6573 17.9281 20.7158 17.6134 20.8334 17.3196C20.951 17.0259 21.1254 16.7592 21.3463 16.5352C21.567 16.3112 21.8298 16.1343 22.119 16.0153C22.4081 15.8962 22.7179 15.8373 23.0299 15.8419C23.3418 15.8466 23.6497 15.9148 23.9353 16.0424C24.2209 16.1701 24.4784 16.3547 24.6926 16.5852Z"
          fill={logoColor}
        />
        <path
          d="M50.4544 49.4488C49.8946 48.8798 49.1725 46.786 48.7415 45.3579C48.6978 45.2178 48.6928 45.0682 48.7271 44.9255C48.7614 44.7827 48.8335 44.6523 48.9358 44.5483C49.038 44.4444 49.1663 44.371 49.3069 44.3362C49.4473 44.3014 49.5945 44.3064 49.7323 44.3508C51.1374 44.7889 53.175 45.4888 53.7516 46.0919C54.1697 46.5403 54.3993 47.1369 54.3914 47.7547C54.3835 48.3725 54.1387 48.9628 53.7093 49.4001C53.2798 49.8374 52.6994 50.0871 52.0916 50.0961C51.4837 50.1052 50.8964 49.873 50.4544 49.4488Z"
          fill={logoColor}
        />
        <path
          d="M21.4066 46.1373C21.9664 45.5684 24.0264 44.8344 25.4315 44.3906C25.5694 44.3462 25.7165 44.3412 25.857 44.376C25.9975 44.4109 26.1258 44.4842 26.2281 44.5881C26.3303 44.6921 26.4024 44.8225 26.4368 44.9653C26.471 45.108 26.466 45.2576 26.4224 45.3977C25.9913 46.8315 25.3028 48.9025 24.7094 49.4886C24.2682 49.9135 23.6812 50.1468 23.0733 50.1388C22.4655 50.1307 21.8847 49.882 21.4545 49.4455C21.0243 49.009 20.7786 48.4191 20.7697 47.8013C20.7607 47.1835 20.9892 46.5865 21.4066 46.1373Z"
          fill={logoColor}
        />
        <path
          d="M53.6565 19.8455C53.0967 20.4144 51.0367 21.1484 49.6316 21.5922C49.4939 21.6345 49.3476 21.638 49.2081 21.6024C49.0686 21.5668 48.9413 21.4934 48.8396 21.3901C48.7379 21.2867 48.6657 21.1573 48.6307 21.0155C48.5957 20.8738 48.5991 20.725 48.6407 20.5851C49.0774 19.157 49.7603 17.0803 50.3593 16.4943C50.7966 16.0498 51.3895 15.8002 52.0079 15.8002C52.6262 15.8002 53.2192 16.0498 53.6565 16.4943C54.0938 16.9387 54.3394 17.5414 54.3394 18.1699C54.3394 18.7983 54.0938 19.401 53.6565 19.8455Z"
          fill={logoColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_327_1125">
          <rect width="220" height="66" fill="white" />
        </clipPath>
      </defs>
    </S.SVG>
  );
};

Logo.defaultProps = defaultProps;

export default Logo;
