export const defaultFontSize = 24; // px

export const breakpointTablet = 712; // px
export const breakpointDesktop = 1025; // px
export const breakpointDesktopLarge = 1280; // px

export const desktopMinHeight = 600; // px
export const breakpointMobileSmallHeight = 600; // px

export const mobileBaseWidth = 390; // px
export const desktopBaseWidth = 1728; // px

export const mobileMinFontSize = 11; // px
export const desktopMinFontSize = 12; // px

export const mobileScalableFontSize = 2; // vw
export const mobileSmallHeightScalableFontSize = 1.2; // vw
export const tabletScalableFontSize = 1.4; // vw
export const desktopScalableFontSize = 900 / desktopBaseWidth; // vw
export const desktopWideScalableFontSize = 900 / desktopBaseWidth; // vw

export const desktopWideAspectRatio = '20 / 11';
