import create from 'zustand';

export interface WindowStoreType {
  isMobile: boolean;
  setIsMobile: (isMobile: boolean) => void;

  isTablet: boolean;
  setIsTablet: (isTablet: boolean) => void;

  isDesktop: boolean;
  setIsDesktop: (isDesktop: boolean) => void;

  setCurrentBreakpoint: (data: {
    isMobile?: boolean;
    isDesktop?: boolean;
    isTablet?: boolean;
  }) => void;
}

const useGlobalStore = create(
  (
    set: (partial: (store: WindowStoreType) => Partial<WindowStoreType>) => void
  ) => {
    return {
      isMobile: false,
      setIsMobile: state => {
        set(() => ({
          isMobile: state,
        }));
      },
      isTablet: false,
      setIsTablet: state => {
        set(() => ({
          isTablet: state,
        }));
      },
      isDesktop: false,
      setIsDesktop: state => {
        set(() => ({
          isDesktop: state,
        }));
      },
      setCurrentBreakpoint: state => {
        set(curState => ({
          ...curState,
          isTablet: state.isTablet,
          isDesktop: state.isDesktop,
          isMobile: state.isMobile,
        }));
      },
    };
  }
);

export default useGlobalStore;
