import React, { useEffect, useState } from 'react';

import Interactive from 'components/Interactive/Interactive';
import { CookieType } from 'types/common';
import gtm from 'u9/utils/gtm';

import * as S from './CookieBar.styles';

export interface CookieBarProps {
  className?: string;
  data: CookieType;
}

interface State {
  analytics: string | null;
  required: string | null;
}

const defaultProps: Partial<CookieBarProps> = {};

const cookieMap = {
  'essential-cookies': 'required',
  analytics: 'analytics',
};

const CookieBar: React.FC<CookieBarProps> = ({
  className,
  data,
}: CookieBarProps) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isDismissed, setIsDismissed] = useState(true);

  const [cookies, setCookies] = useState<State>({
    analytics: null,
    required: null,
  });

  const onSubmit = () => {
    localStorage.setItem('cookies-required', 'true');
    localStorage.setItem('cookies-analytics', cookies.analytics);
    setIsDismissed(true);
  };

  useEffect(() => {
    setCookies({
      required: localStorage?.getItem('cookies-required'),
      analytics: localStorage?.getItem('cookies-analytics'),
    });
    setIsDismissed(!!localStorage?.getItem('cookies-required'));
  }, []);

  useEffect(() => {
    if (cookies.analytics === 'true') {
      gtm.initialize(true, false);
    }
  }, [cookies]);

  const renderCookieBanner = () => (
    <S.CollapsedContent
      key="cookie-banner"
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      // exit={{ opacity: 0 }}
    >
      <S.LeftContent>
        <S.CookiesTitle>{data.title}</S.CookiesTitle>
        <S.Description richText={data.description} />
      </S.LeftContent>
      <S.RightContent>
        <S.Buttons>
          <Interactive>
            <S.Settings onClick={() => setIsSettingsOpen(true)}>
              {data.openSettingsCta}
            </S.Settings>
          </Interactive>
          <S.CookieButton
            label={data.rejectCta}
            onClick={() => {
              setCookies({ analytics: 'false', required: 'true' });
              onSubmit();
            }}
            empty
          ></S.CookieButton>
          <S.CookieButton
            label={data.acceptCta}
            onClick={() => {
              setCookies({ analytics: 'true', required: 'true' });
              onSubmit();
            }}
          ></S.CookieButton>
        </S.Buttons>
      </S.RightContent>
    </S.CollapsedContent>
  );

  const renderSettings = () => (
    <S.CollapsedContent
      key="cookie-settings"
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      // exit={{ opacity: 0 }}
    >
      <S.LeftContent>
        <S.CookiesTitle>{data.title}</S.CookiesTitle>
        {data.cookies.map(option => (
          <S.CookieOption
            key={`settings-option-${option.idx}`}
            onClick={() => {
              const change = { ...cookies };
              change[cookieMap[option.idx]] = !change[cookieMap[option.idx]];
              setCookies(change);
            }}
          >
            <S.OptionWrapper>
              <S.Title>{option.title}</S.Title>
              <S.CookieDescription>{option.description}</S.CookieDescription>
            </S.OptionWrapper>
            <S.CheckBox
              checked={cookies[cookieMap[option.idx]]}
              onClick={() => {}}
            />
          </S.CookieOption>
        ))}
      </S.LeftContent>
      <S.RightContent>
        <S.Buttons settings>
          <S.CookieButton
            label={data.declineAllCta}
            onClick={() => {
              setCookies({ analytics: 'false', required: 'true' });
              onSubmit();
            }}
            empty
          ></S.CookieButton>
          <S.CookieButton
            label={data.saveCta}
            onClick={() => {
              onSubmit();
            }}
            empty
          ></S.CookieButton>
          <S.CookieButton
            label={data.acceptAllCta}
            onClick={() => {
              setCookies({ analytics: 'true', required: 'true' });
              onSubmit();
            }}
          ></S.CookieButton>
        </S.Buttons>
      </S.RightContent>
    </S.CollapsedContent>
  );

  return (
    <S.Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      // transition={{ delay: 1.5 }}
      layout
      isDismissed={isDismissed}
      className={className}
    >
      {/* <AnimatePresence mode="wait"> */}
      {isSettingsOpen ? renderSettings() : renderCookieBanner()}
      {/* </AnimatePresence> */}
    </S.Wrapper>
  );
};

CookieBar.defaultProps = defaultProps;

export default CookieBar;
