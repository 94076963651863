import { MotionValue } from 'framer-motion';
import create from 'zustand';

import { LIGHT_BG } from 'utils/styles/theme';

export interface GlobalStoreType {
  isMenuOpen: boolean;
  setIsMenuOpen: (state: boolean) => void;

  isWindowTooSmall: boolean;
  setWindowTooSmall: (state: boolean) => void;

  shouldRotateDevice: boolean;
  setRotateDevice: (state: boolean) => void;

  isSchedilingOpen: boolean;
  setIsSchedulingOpen: (state: boolean) => void;

  bgColorTransition: MotionValue;
}

const useGlobalStore = create(
  (
    set: (partial: (store: GlobalStoreType) => Partial<GlobalStoreType>) => void
  ) => {
    const initialBgColor = new MotionValue();
    initialBgColor.set(LIGHT_BG);

    return {
      isMenuOpen: false,
      setIsMenuOpen: state => {
        set(() => ({
          isMenuOpen: state,
        }));
      },

      isWindowTooSmall: false,
      setWindowTooSmall: state => {
        set(() => ({
          isWindowTooSmall: state,
        }));
      },

      shouldRotateDevice: false,
      setRotateDevice: state => {
        set(() => ({
          shouldRotateDevice: state,
        }));
      },

      isSchedilingOpen: false,
      setIsSchedulingOpen: state => {
        set(() => ({
          isSchedilingOpen: state,
        }));
      },

      bgColorTransition: initialBgColor,
    };
  }
);

export default useGlobalStore;
