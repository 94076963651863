import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';

export const Wrapper = styled(motion.div)`
  display: flex;
`;

export const Button = styled.button<{ empty: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  height: 7vh;
  white-space: nowrap;

  background-color: ${colors.green};
  color: ${colors.mediumGreen};
  border-radius: ${layout.borderRadius}px;

  ${setTypography('button')}

  ${({ empty }) =>
    empty &&
    css`
      background-color: transparent;
      color: ${colors.white};
      border: 2px solid ${colors.green};
    `}
`;
