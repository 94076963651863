import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import BurgerMenuButtonCmp from 'components/BurgerMenuButton';
import LogoComponent from 'components/Logo';
import { grid, MARGIN, setTypography } from 'utils/styles/mixins';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  width: 100%;
  grid-column: 1 / -1;
  color: ${colors.white};
  background-color: ${colors.mediumGreen};
  z-index: 10;
  ${grid()};
`;

export const Logo = styled(LogoComponent)``;

export const LogoWrapper = styled(motion.div)`
  position: fixed;
  z-index: 99;
  padding: 18px 0 18px 0;
  grid-column: 2;
  top: 0;
  width: 187px;
  height: min(106px, 10vh);
  left: ${MARGIN};
  cursor: pointer;

  ${mediaTablet(css`
    left: 10vw;
  `)}

  ${mediaDesktop(`
    left: ${MARGIN};
  `)}
`;

export const CtaWrapper = styled.div`
  position: fixed;
  top: 18px;
  right: 5vw;

  display: flex;
  align-items: center;
  height: 70px;
  z-index: 99;
  display: none;

  ${mediaDesktop(css`
    display: flex;
  `)}
`;

export const MenuWrapper = styled.div`
  height: min(106px, 10vh);
  grid-column: 5/-4;
  padding-right: 4vw;
  width: 100%;

  display: none;
  align-items: center;
  justify-content: space-between;

  ${mediaDesktop(css`
    display: flex;
  `)}
`;

export const MenuItem = styled(motion.div)`
  ${setTypography('button')}
  /* margin-right: 5rem; */
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
`;

export const BurgerMenuButton = styled(BurgerMenuButtonCmp)`
  position: fixed !important;
  top: 18px;
  right: 5vw;

  /* ${mediaTablet(
    css`
      right: 10vw;
    `
  )} */
`;
