import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { EXCLUDE_INTERACTIVE_BG } from 'utils/config';

export const useExcludedRoute = () => {
  const { pathname } = useRouter();

  const isExcluded = useMemo(() => {
    return !!EXCLUDE_INTERACTIVE_BG.some(v => pathname.includes(v));
  }, [pathname]);

  return isExcluded;
};
