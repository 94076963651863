export const ISR_TIMEOUT = process.env.ENV === 'local' ? 10 : 60;

export const EXCLUDE_INTERACTIVE_BG = ['blog/'];

type LinksType = {
  [key: string]: {
    ABOUT: string;
    BLOG: string;
    CONTACT: string;
    PRIVACY: string;
    PROCESS: string;
  };
};

export const LINKS: LinksType = {
  en: {
    ABOUT: 'about',
    BLOG: 'blog',
    CONTACT: 'contact',
    PRIVACY: 'privacy',
    PROCESS: 'process',
  },
  pl: {
    ABOUT: 'o-nas',
    BLOG: 'blog',
    CONTACT: 'contact',
    PRIVACY: 'privacy',
    PROCESS: 'proces',
  },
};
