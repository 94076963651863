import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';

import Cta from 'components/Cta';
import { useExcludedRoute } from 'hooks/useExcludedRoute';
import { useGlobalStore } from 'store';
import { ButtonType } from 'types/common';
import { NavType } from 'types/nav';
import { colors } from 'utils/styles/theme';

import * as S from './Header.styles';

export interface HeaderProps {
  className?: string;
  data: NavType;
  cta: ButtonType;
}

const defaultProps: Partial<HeaderProps> = {};

const Header: React.FC<HeaderProps> = ({
  className,
  data,
  cta,
}: HeaderProps) => {
  const { isMenuOpen, setIsMenuOpen, setIsSchedulingOpen } = useGlobalStore();
  const [isVisible, setIsVisible] = useState(true);
  const lastScroll = useRef(0);
  const { push, pathname } = useRouter();
  const isExcluded = useExcludedRoute();

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const onScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsVisible(
      scrollTop < lastScroll.current || scrollTop < window.innerHeight * 0.2
    );
    lastScroll.current = scrollTop;
  };

  return (
    <>
      <S.LogoWrapper
        animate={{ y: isVisible ? '0%' : '-100%' }}
        onClick={() => push('/', null, { scroll: false })}
      >
        <S.Logo />
      </S.LogoWrapper>
      <S.Wrapper
        className={className}
        animate={{
          y: isVisible ? '0%' : '-100%',
          background: isExcluded ? colors.mediumGreen : 'transparent',
        }}
        // transition={{ duration: 1 }}
      >
        <S.MenuWrapper>
          {data?.menuItems.map(item => (
            <Link key={item.label} href={item.href} scroll={false}>
              <S.MenuItem
                whileHover={{ scale: 1.2 }}
                animate={{ scale: pathname === item.href ? 1.2 : 1 }}
              >
                {item.label}
              </S.MenuItem>
            </Link>
          ))}
        </S.MenuWrapper>
      </S.Wrapper>
      {cta && (
        <S.CtaWrapper>
          <Cta
            label={cta.label}
            onClick={() => {
              setIsSchedulingOpen(true);
            }}
          />
        </S.CtaWrapper>
      )}
      <S.BurgerMenuButton
        // animate={{ y: isVisible ? '0%' : '-100%' }}
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
        }}
        isOpen={isMenuOpen}
      />
    </>
  );
};

Header.defaultProps = defaultProps;

export default Header;
