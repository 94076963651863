import { HTMLMotionProps } from 'framer-motion';
import React from 'react';

import { colors } from 'utils/styles/theme';

import * as S from './HalfLogo.styles';

export interface HalfLogoProps {
  className?: string;
  color?: string;
}

const defaultProps: Partial<HalfLogoProps> = {
  color: colors.green,
};

const HalfLogo: React.FC<HalfLogoProps & HTMLMotionProps<'div'>> = ({
  className,
  color,
}: HalfLogoProps) => {
  return (
    <S.Svg
      className={className}
      width="62"
      height="110"
      viewBox="0 0 62 110"
      fill="red"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1425 19.7787C19.1425 17.1689 20.2102 14.6659 22.111 12.8197C24.0118 10.9734 26.5901 9.93491 29.2795 9.93246H59.1476C59.4688 9.93246 59.777 9.80862 60.0042 9.58815C60.2313 9.3677 60.3589 9.0687 60.3589 8.75693V1.70373C60.3589 1.39196 60.2313 1.09296 60.0042 0.872503C59.777 0.652048 59.4688 0.528198 59.1476 0.528198H29.2795C26.6748 0.528198 24.0957 1.0262 21.6894 1.99374C19.2831 2.96129 17.0968 4.37942 15.2555 6.16712C13.4141 7.95482 11.9539 10.0771 10.9579 12.4126C9.96203 14.7481 9.45013 17.2511 9.45141 19.7787V45.0198C9.44372 46.4191 8.86549 47.7586 7.8431 48.7454C6.82071 49.7322 5.43732 50.2862 3.9953 50.2862H1.64035C1.31907 50.2862 1.01094 50.41 0.783762 50.6304C0.556582 50.8509 0.428955 51.1499 0.428955 51.4617V58.5149C0.428955 58.8266 0.556582 59.1257 0.783762 59.3461C1.01094 59.5666 1.31907 59.6904 1.64035 59.6904H3.9953C5.44234 59.6904 6.83013 60.2483 7.85335 61.2412C8.87657 62.2341 9.45141 63.5808 9.45141 64.985V90.2167C9.44248 92.7494 9.94866 95.2592 10.941 97.6018C11.9334 99.9444 13.3923 102.074 15.2343 103.868C17.0763 105.662 19.265 107.087 21.6751 108.058C24.0853 109.031 26.6695 109.532 29.2795 109.533H59.1476C59.4688 109.533 59.777 109.409 60.0042 109.189C60.2313 108.968 60.3589 108.67 60.3589 108.357V101.304C60.3589 100.992 60.2313 100.694 60.0042 100.473C59.777 100.253 59.4688 100.129 59.1476 100.129H29.2795C27.9462 100.129 26.6259 99.8739 25.3943 99.3783C24.1626 98.8827 23.0436 98.1567 22.1013 97.2416C21.159 96.3266 20.4118 95.2395 19.9024 94.044C19.3931 92.8483 19.1316 91.5669 19.1329 90.2731V65.0132C19.1404 61.2906 17.6848 57.7054 15.0626 54.9883C17.6781 52.2777 19.1362 48.7049 19.1425 44.9916V19.7787Z"
        fill={color}
      />
      <path
        d="M29.3842 51.104C30.8088 51.104 34.4623 52.6933 36.7107 53.8501C36.932 53.9621 37.1176 54.131 37.247 54.3383C37.3765 54.5455 37.4449 54.7832 37.4449 55.0256C37.4449 55.2679 37.3765 55.5057 37.247 55.7128C37.1176 55.9201 36.932 56.089 36.7107 56.2011C34.4623 57.3578 30.8088 58.9378 29.3842 58.9378C28.3479 58.8885 27.3708 58.4543 26.6553 57.7252C25.9399 56.996 25.5408 56.0277 25.5408 55.0209C25.5408 54.0141 25.9399 53.0458 26.6553 52.3166C27.3708 51.5875 28.3479 51.1533 29.3842 51.104Z"
        fill={color}
      />
      <path
        d="M58.4382 31.4774L42.9324 46.5242C42.5811 46.8628 42.3824 47.3227 42.38 47.8032V62.3234C42.3824 62.8039 42.5811 63.2638 42.9324 63.6024L58.4382 78.6492C58.6984 78.9026 59.0304 79.0753 59.3919 79.1454C59.7535 79.2153 60.1284 79.1796 60.4689 79.0426C60.8094 78.9056 61.1004 78.6735 61.3048 78.3757C61.5091 78.078 61.6178 77.728 61.6169 77.3702V69.2825C61.6175 69.0452 61.5699 68.8101 61.4768 68.5906C61.3836 68.3712 61.2468 68.1717 61.0742 68.0036L47.6908 54.9881L61.0742 41.9914C61.2468 41.8232 61.3836 41.6238 61.4768 41.4043C61.5699 41.1849 61.6175 40.9498 61.6169 40.7124V32.7282C61.6121 32.3728 61.4994 32.0267 61.2927 31.7333C61.0862 31.4398 60.7949 31.212 60.4555 31.0785C60.1161 30.9449 59.7437 30.9116 59.3848 30.9826C59.026 31.0535 58.6967 31.2257 58.4382 31.4774Z"
        fill={color}
      />
      <path
        d="M44.1727 27.8573C45.1418 28.7977 46.392 32.2585 47.1382 34.619C47.2138 34.8505 47.2224 35.0977 47.1631 35.3337C47.1038 35.5697 46.9788 35.7853 46.8018 35.9571C46.6248 36.1288 46.4027 36.2501 46.1594 36.3077C45.9163 36.3652 45.6615 36.3569 45.4229 36.2835C42.9904 35.55 39.4628 34.4027 38.455 33.3964C38.0629 33.0358 37.7493 32.6028 37.5327 32.1226C37.3161 31.6424 37.2008 31.1251 37.1938 30.601C37.1868 30.0769 37.2881 29.5567 37.4918 29.0712C37.6954 28.5857 37.9972 28.1449 38.3796 27.7745C38.7618 27.4043 39.2167 27.112 39.7172 26.9153C40.2179 26.7184 40.7541 26.621 41.2942 26.6287C41.8342 26.6364 42.3672 26.7491 42.8616 26.9601C43.3561 27.1712 43.8019 27.4762 44.1727 27.8573Z"
        fill={color}
      />
      <path
        d="M38.4845 76.7027C39.4536 75.7623 43.02 74.5491 45.4524 73.8156C45.691 73.7422 45.9458 73.7339 46.189 73.7914C46.4322 73.8491 46.6543 73.9703 46.8314 74.142C47.0083 74.3138 47.1333 74.5295 47.1927 74.7654C47.252 75.0014 47.2434 75.2486 47.1678 75.4802C46.4215 77.85 45.2295 81.2732 44.2023 82.2418C43.4384 82.9442 42.4222 83.3298 41.3699 83.3165C40.3177 83.3033 39.3122 82.8921 38.5675 82.1706C37.8227 81.4491 37.3973 80.4741 37.3818 79.453C37.3663 78.4319 37.7619 77.4452 38.4845 76.7027Z"
        fill={color}
      />
    </S.Svg>
  );
};

HalfLogo.defaultProps = defaultProps;

export default HalfLogo;
