import React from 'react';

import * as S from './Interactive.styles';

export interface InteractiveProps {
  className?: string;
  scale?: number;
  children: any;
}

const defaultProps: Partial<InteractiveProps> = {
  scale: 1.1,
};

const Interactive: React.FC<InteractiveProps> = ({
  className,
  scale,
  children,
}: InteractiveProps) => {
  return (
    <S.Wrapper id="interactive" className={className} whileHover={{ scale }}>
      {children}
    </S.Wrapper>
  );
};

Interactive.defaultProps = defaultProps;

export default Interactive;
