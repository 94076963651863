import React from 'react';

import RichText from 'components/RichText/RichText';
import { FooterType } from 'types/common';

import * as S from './Footer.styles';

export interface FooterProps {
  className?: string;
  data: FooterType;
}

const defaultProps: Partial<FooterProps> = {};

const Footer: React.FC<FooterProps> = ({ className, data }: FooterProps) => {
  return (
    <S.Wrapper className={className}>
      <S.Logo />
      <S.Content>
        <S.CompanyInfo>
          <RichText richText={data.companyInfo} />
        </S.CompanyInfo>
        <S.Separator />
        <S.ContactInfo>
          <RichText richText={data.contactInfo} />
        </S.ContactInfo>
        <S.Separator />
        <S.Links>
          <RichText richText={data.links} />
        </S.Links>
        <S.Separator />
        <S.LegalLinks>
          <RichText richText={data.legalLinks} />
        </S.LegalLinks>
      </S.Content>
    </S.Wrapper>
  );
};

Footer.defaultProps = defaultProps;

export default Footer;
