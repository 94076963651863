import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { useGlobalStore } from 'store';
import { NavType } from 'types/nav';

import * as S from './MobileMenu.styles';

export interface MobileMenuProps {
  className?: string;
  data: NavType;
}

const defaultProps: Partial<MobileMenuProps> = {};

const MobileMenu: React.FC<MobileMenuProps> = ({
  className,
  data,
}: MobileMenuProps) => {
  const { isMenuOpen, setIsMenuOpen, setIsSchedulingOpen } = useGlobalStore();

  const { pathname } = useRouter();

  useEffect(() => {
    setIsMenuOpen(false);
  }, [pathname, setIsMenuOpen]);

  return (
    <S.Wrapper
      className={className}
      initial={{
        clipPath: 'circle(0% at calc(100% - 50px) 48px',
      }}
      animate={{
        clipPath: isMenuOpen
          ? 'circle(150% at calc(100% - 50px) 48px)'
          : 'circle(0% at calc(100% - 50px) 48px)',
      }}
      transition={{
        duration: 0.4,
      }}
    >
      {data?.menuItems.map(item => (
        <S.Interactive key={`${item.label}-Menuitem`}>
          <Link href={item.href}>
            <S.MenuItem>{item.label}</S.MenuItem>
          </Link>
        </S.Interactive>
      ))}
      <S.Cta
        label={data?.mobileCta.label}
        onClick={() => {
          setIsSchedulingOpen(true);
          setIsMenuOpen(false);
        }}
      />
    </S.Wrapper>
  );
};

MobileMenu.defaultProps = defaultProps;

export default MobileMenu;
