import styled, { css } from 'styled-components';

import LogoCmp from 'components/Logo';
import { grid, setVh } from 'utils/styles/mixins';
import { mediaDesktop } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  padding-top: 3vh;
  grid-column: 1/-1;
  min-height: ${setVh(30)};
  background-color: ${colors.darkGreen};
  color: ${colors.white};
  z-index: 1;
  ${grid()}

  ${mediaDesktop(
    css`
      z-index: 2;
    `
  )}
`;

export const Logo = styled(LogoCmp)`
  grid-column: 2 / span 4;
  height: auto;

  ${mediaDesktop(
    css`
      grid-column: 2;
    `
  )}
`;

export const CompanyInfo = styled.div``;
export const ContactInfo = styled.div``;
export const Links = styled.div`
  a {
    font-weight: bold;
  }
`;
export const LegalLinks = styled.div`
  a {
    font-weight: bold;
  }
`;

export const Separator = styled.div`
  height: 30%;
  width: 2px;
  background-color: ${colors.white};

  display: none;

  ${mediaDesktop(css`
    display: block;
  `)}
`;

export const Content = styled.div`
  grid-row: 2;
  grid-column: 2/-5;
  display: flex;
  flex-direction: column;
  margin: 2rem 0;

  > div {
    margin-bottom: 2rem;
  }

  ${mediaDesktop(css`
    grid-column: 2/-4;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `)}
`;
