import isValidProp from '@emotion/is-prop-valid';
import { AnimatePresence, MotionConfig } from 'framer-motion';
import type { AppProps } from 'next/app';
import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import CallendlyModal from 'components/CallendlyModal/CallendlyModal';
import Layout from 'containers/Layout';
import useScreenBreakpoints from 'hooks/useScreenBreakpoints';
import { setupStoreDevTools, useCopyStore } from 'store';
import { ButtonType, CookieType, FooterType, SeoType } from 'types/common';
import { NavType } from 'types/nav';
import Head from 'u9/components/Head/Head';
import Version from 'u9/components/versioning/Version/Version';
import VersionScreen from 'u9/components/versioning/VersionScreen/VersionScreen';
import customFonts from 'utils/styles/fonts';
import GlobalStyles from 'utils/styles/globalStyles';
import theme from 'utils/styles/theme';

import 'intersection-observer';

export interface CustomPageProps {
  sharedCopy: any;
  pageData: { header: NavType; cta?: ButtonType };
  footer: FooterType;
  cookies: CookieType;
  head: SeoType;
}

const App = ({ Component, pageProps, router }: AppProps<CustomPageProps>) => {
  const [isMounted, setMounted] = useState(false);
  const { setCopy } = useCopyStore();
  const setup = useScreenBreakpoints();

  useEffect(() => {
    if (process.env.IS_DEBUG) setupStoreDevTools();
    if (pageProps.sharedCopy) setCopy(pageProps.sharedCopy);

    setMounted(setup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setup]);

  return (
    <ThemeProvider theme={{ ...theme, locale: router.locale }}>
      <MotionConfig isValidProp={isValidProp}>
        <GlobalStyles />
        <style dangerouslySetInnerHTML={{ __html: customFonts }} />

        <Head {...pageProps.head} />
        {/* <LanguageSelector /> */}

        {/* <NonFunctionals
            // If the non-functionals are the page component, pass the initial copy
            // fetched from the server instead of the stored copy
            initialCopy={
              router.route === ROUTES.NOT_FOUND ? pageProps.initialCopy : copy
            }
            router={router}
          > */}
        <CallendlyModal />
        <Layout {...pageProps}>
          <AnimatePresence
            // mode="wait"
            // initial={false}
            onExitComplete={() => {
              if (typeof window !== 'undefined') {
                window.scrollTo({ top: 0 });
              }
            }}
          >
            <Component key={router.route} router={router} {...pageProps} />
          </AnimatePresence>
        </Layout>
        {/* </NonFunctionals> */}

        {process.env.IS_DEBUG && <Version />}
        {isMounted
          ? process.env.IS_DEBUG &&
            window.location.hash === '#version' && <VersionScreen />
          : null}
      </MotionConfig>
    </ThemeProvider>
  );
};

export default App;
