import styled from 'styled-components';

import HalfLogo from 'components/HalfLogo';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  position: relative;
  width: 3rem;
  height: 3rem;

  cursor: pointer;
`;

export const Bracket = styled(HalfLogo)`
  position: absolute;
  width: 100%;
  height: 100%;
  left: -40%;

  &:last-child {
    left: auto;
    right: -40%;
    transform: rotate(180deg);
  }
`;

export const Checked = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::after,
  &::before {
    content: ' ';
    position: absolute;
    width: 1px;
    background-color: ${colors.white};
    height: 70%;
    left: 50%;
    top: calc(50% - 0.5px);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;
