import { useScroll, useSpring, useTransform, useVelocity } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react';

import { useGlobalStore } from 'store';
import {
  darkBackgroundColors,
  lightBackgroundColors,
} from 'utils/styles/theme';

import { convertColor, Gradient, rgba2hex } from './utils';

import * as S from './Background.styles';

export interface BackgroundProps {
  className?: string;
}

const defaultProps: Partial<BackgroundProps> = {};

const Background: React.FC<BackgroundProps> = ({
  className,
}: BackgroundProps) => {
  const { scrollYProgress } = useScroll();
  const scrollVelocity = useVelocity(scrollYProgress);
  const smoothedScroll = useSpring(scrollVelocity, { stiffness: 300 });
  const bg = useRef(null);
  const initialized = useRef(false);
  const { pathname } = useRouter();

  const { bgColorTransition } = useGlobalStore();

  const color1 = useTransform(
    bgColorTransition,
    [1, 0],
    [lightBackgroundColors[0], darkBackgroundColors[0]]
  );
  const color2 = useTransform(
    bgColorTransition,
    [1, 0],
    [lightBackgroundColors[1], darkBackgroundColors[1]]
  );

  const finalColor1 = useTransform(color1, val => {
    return convertColor(rgba2hex(val));
  });
  const finalColor2 = useTransform(color2, val => {
    return convertColor(rgba2hex(val));
  });

  useEffect(() => {
    if (bg.current) {
      // light colour at the top
      bgColorTransition.set(1);
    }
  }, [pathname]);

  useEffect(() => {
    if (!initialized.current) {
      bg.current = new Gradient(
        smoothedScroll,
        [finalColor1, finalColor2],
        [0, 1, 1, 0]
      );
      bg.current.initGradient('#gradient-canvas', [finalColor1, finalColor2]);

      initialized.current = true;
    }
  }, []);

  return (
    <S.Wrapper
      // animate={{ opacity: opacity }}
      className={className}
      id="gradient-canvas"
      data-transition-in
    />
  );
};

Background.defaultProps = defaultProps;

export default Background;
