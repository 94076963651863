import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import CheckBoxCmp from 'components/CheckBox/CheckBox';
import ButtonCmp from 'components/Cta/Cta';
import { Button } from 'components/Cta/Cta.styles';
import RichText from 'components/RichText/RichText';
import { MARGIN, setTypography } from 'utils/styles/mixins';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled(motion.div)<{ isDismissed: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  padding: ${MARGIN} ${MARGIN};

  background-color: ${colors.darkGreen};
  color: ${colors.white};

  z-index: 10;

  ${({ isDismissed }) =>
    isDismissed &&
    css`
      display: none;
    `}

  ${mediaTablet(
    css`
      /* padding: 10vw; */
    `
  )}
`;

export const CollapsedContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mediaDesktop(css`
    flex-direction: row;
  `)}
`;

export const LeftContent = styled.div`
  ${mediaDesktop(css`
    max-width: 50vw;
  `)}
`;

export const RightContent = styled.div`
  display: flex;

  margin-top: 4rem;

  ${mediaDesktop(
    css`
      margin: 3rem 0;
    `
  )}
`;

export const Description = styled(RichText)`
  a {
    color: ${colors.green};
    text-decoration: underline;
  }
`;

export const CookieButton = styled(ButtonCmp)`
  width: 100%;

  ${Button} {
    padding: 0 4rem;
    width: 100%;
  }

  &:nth-child(2) {
    margin: 2rem 0;
  }

  ${mediaDesktop(`
    ${Button} {
      padding: 0 7rem;
    }

    &:nth-child(2) {
      margin: 0 6rem;
    }
  `)}
`;

export const Buttons = styled.div<{ settings?: boolean }>`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ${mediaDesktop(css`
    flex-direction: row;
    align-items: center;
  `)}

  ${({ settings }) =>
    settings &&
    mediaDesktop(`
      flex-direction: column-reverse;
      align-items: flex-end;

      ${CookieButton} {
        margin: 0;
      }
    `)}
`;

export const Settings = styled.div`
  color: ${colors.green};
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;

  text-align: center;
`;

export const AcceptCta = styled(ButtonCmp)``;

export const CookieOption = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  ${mediaDesktop(
    css`
      width: 57vw;
      max-width: 1100px;
    `
  )}
`;

export const Title = styled.div`
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const CookieDescription = styled.div`
  ${mediaDesktop(css`
    width: 80rem;
  `)}
`;

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CookiesTitle = styled.div`
  ${setTypography('heading3')}
  margin-bottom: 2rem;
`;

export const CheckBox = styled(CheckBoxCmp)`
  width: 20rem;
`;
