import { FlattenSimpleInterpolation } from 'styled-components';

import { getViewportInfo } from 'u9/utils/dom';
import { isBrowser, isDesktop, isIOS, isMobile } from 'u9/utils/platform';

import * as VARS from './vars';

export const mqMobile = `(max-width: ${VARS.breakpointTablet - 1}px)`;
export const mediaMobile = (content: string | FlattenSimpleInterpolation) =>
  `@media ${mqMobile} {
    ${content}
  }`;

export const mqMobileSmallHeight = `(max-height: ${VARS.breakpointMobileSmallHeight}px)`;
export const mediaMobileSmallHeight = (content: string) =>
  `@media ${mqMobileSmallHeight} {
    ${content}
  }`;

export const mqTablet = `(min-width: ${VARS.breakpointTablet}px)`;
export const mediaTablet = (content: string | FlattenSimpleInterpolation) =>
  `@media ${mqTablet} {
    ${content}
  }`;

export const mqDesktop = `(min-width: ${VARS.breakpointDesktop}px) and (min-height: ${VARS.desktopMinHeight}px)`;
export const mediaDesktop = (content: string | FlattenSimpleInterpolation) =>
  `@media ${mqDesktop} {
    ${content}
  }`;

const mqDesktopLarge = `(min-width: ${VARS.breakpointDesktopLarge}px)`;
export const mediaDesktopLarge = (
  content: string | FlattenSimpleInterpolation
) =>
  `@media ${mqDesktopLarge} {
    ${content}
  }`;

export const mqDesktopWide = `(min-width: ${VARS.breakpointDesktop}px) and (min-aspect-ratio: ${VARS.desktopWideAspectRatio})`;
export const mediaDesktopWide = (
  content: string | FlattenSimpleInterpolation
) =>
  `@media ${mqDesktopWide} {
    ${content}
  }`;

export const mqLandscape = '(orientation: landscape)';
export const mediaLandscape = (content: string | FlattenSimpleInterpolation) =>
  `@media ${mqLandscape} {
    ${content}
  }`;

export const mqPortrait = '(orientation: portrait)';
export const mediaPortrait = (content: string | FlattenSimpleInterpolation) =>
  `@media ${mqPortrait} {
    ${content}
  }`;

export const matchMobile = {
  match: isBrowser() && window.matchMedia(mqMobile),
  scalableFontSize: VARS.mobileScalableFontSize,
  minFontSize: VARS.mobileMinFontSize,
  setVh: false,
};

export const matchMobileSmallHeight = {
  match: isBrowser() && window.matchMedia(mqMobileSmallHeight),
  scalableFontSize: VARS.mobileSmallHeightScalableFontSize,
  minFontSize: VARS.mobileMinFontSize,
  setVh: false,
};

export const matchTablet = {
  match: isBrowser() && window.matchMedia(mqTablet),
  scalableFontSize: VARS.tabletScalableFontSize,
  minFontSize: VARS.desktopMinFontSize,
  setVh: false,
};

export const matchDesktop = {
  match: isBrowser() && window.matchMedia(mqDesktop),
  scalableFontSize: VARS.desktopScalableFontSize,
  minFontSize: VARS.desktopMinFontSize,
  setVh: false,
};

export const matchDesktopWide = {
  match: isBrowser() && window.matchMedia(mqDesktopWide),
  scalableFontSize: VARS.desktopWideScalableFontSize,
  minFontSize: VARS.desktopMinFontSize,
  setVh: true,
};

export const isMobileLayout = () =>
  isMobile() || getViewportInfo().width < VARS.breakpointTablet;

export const setScalableFontSize = () => {
  // iOS doesn't scale non-px units automatically when zooming
  const setDocumentZoom = (value: string) =>
    (isDesktop() || isIOS()) && process.env.ALLOW_USER_ZOOM
      ? `calc(var(--zoomLevel, 1) * ${value})`
      : value;

  const result = `
    font-size: ${setDocumentZoom(`${VARS.mobileScalableFontSize}vw`)};

    ${mediaTablet(`
      font-size: ${setDocumentZoom(`${VARS.tabletScalableFontSize}vw`)};
    `)}

    ${mediaDesktop(`
      font-size: ${setDocumentZoom(`${VARS.desktopScalableFontSize}vw`)};
    `)}

    ${mediaMobileSmallHeight(`
      font-size: ${setDocumentZoom(
        `${VARS.mobileSmallHeightScalableFontSize}vw`
      )};
    `)}

    ${mediaDesktopWide(`
      font-size: ${setDocumentZoom(`${VARS.desktopWideScalableFontSize}vw`)};
    `)}
  `;

  return result;
};
