import React, { useEffect, useState } from 'react';
import { PopupModal } from 'react-calendly';

import { useGlobalStore } from 'store';

// import * as S from './CallendlyModal.styles';

export interface CallendlyModalProps {
  className?: string;
}

const defaultProps: Partial<CallendlyModalProps> = {};

const CallendlyModal: React.FC<CallendlyModalProps> = () => {
  const { isSchedilingOpen, setIsSchedulingOpen } = useGlobalStore();
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return isMounted ? (
    <PopupModal
      url="https://calendly.com/tobiasz-dziubinski-td"
      onModalClose={() => setIsSchedulingOpen(false)}
      open={isSchedilingOpen}
      rootElement={document.getElementById('calendar')}
    />
  ) : null;
};

CallendlyModal.defaultProps = defaultProps;

export default CallendlyModal;
