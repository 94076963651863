import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';

export const Wrapper = styled.div`
  p {
    margin-bottom: 1rem;
  }

  h1 {
    ${setTypography('heading1')}
  }

  h2 {
    ${setTypography('heading2')}
  }

  h3 {
    ${setTypography('heading3')}
  }

  h6 {
    ${setTypography('body')}
    font-weight: 600;
    margin: 1rem 0;
  }

  strong {
    font-weight: bold;
  }

  ul {
    list-style-type: disc;
    list-style-position: outside;
  }
  ol {
    list-style-type: decimal;
    list-style-position: outside;
  }
  ul ul,
  ol ul {
    list-style-type: circle;
    list-style-position: outside;
    margin-left: 15px;
  }
  ol ol,
  ul ol {
    list-style-type: lower-latin;
    list-style-position: outside;
    padding-left: 15px;
  }

  ul,
  ol {
    padding-left: 20px;
  }
`;
